import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';


//add components here
import Dashboard from './components/ui-kit/Dashboard.vue'
import FontUpload from './components/ui-kit/FontUpload.vue'
import Login from './components/ui-kit/Login.vue'
import Settings from './components/ui-kit/Settings.vue'
import Upgrade from './components/ui-kit/Upgrade.vue'
import Premium from './components/ui-kit/Premium.vue'


import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import vuetify from './plugins/vuetify'


Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(VueToast);


const router = new VueRouter({
    routes: [
        {path: '/', redirect: '/dashboard'},
        {path: '/fonts', component: FontUpload, name: "fonts"},
        {
            path: '/dashboard', component: Dashboard, name: "dashboard",

            beforeEnter: (to, from, next) => {
                let shop_url = document.getElementById('shop_domain').value
                let shop_token = document.getElementById('shop_token').value


                // shop_url = 'shristorey.myshopify.com'
                // shop_token = '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'


                if (shop_url && shop_token) {
                    console.log('SHOP: ', shop_url)
                    console.log('SHOP TOKEN: ', shop_token)
                    next();
                } else {
                    //redirect to the login screen here
                    next({
                        name: "login" // back to safety route //
                    });
                }
            },


        },
        {path: '/login', component: Login, name: "login"},
        {
            path: '/settings', component: Settings, name: "settings",


            beforeEnter: (to, from, next) => {
                let shop_url = document.getElementById('shop_domain').value
                let shop_token = document.getElementById('shop_token').value


                // shop_url = 'shristorey.myshopify.com'
                // shop_token = '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'

                if (shop_url && shop_token) {
                    console.log('SHOP: ', shop_url)
                    console.log('SHOP TOKEN: ', shop_token)
                    next();
                } else {
                    //redirect to the login screen here
                    next({
                        name: "login" // back to safety route //
                    });
                }
            },


        },
        {
            path: '/upgrade', component: Upgrade, name: "upgrade",


            beforeEnter: (to, from, next) => {
                let shop_url = document.getElementById('shop_domain').value
                let shop_token = document.getElementById('shop_token').value


                // shop_url = 'shristorey.myshopify.com'
                // shop_token = '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'


                if (shop_url && shop_token) {
                    console.log('SHOP: ', shop_url)
                    console.log('SHOP TOKEN: ', shop_token)
                    next();
                } else {
                    //redirect to the login screen here
                    next({
                        name: "login" // back to safety route //
                    });
                }
            },


        },
        {path: '/premium', component: Premium, name: "premium"},
    ],
    mode: 'history',
})


new Vue({
    render: h => h(App),
    vuetify,
    router
}).$mount('#app')
