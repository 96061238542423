<template>
  <div>


  <!-- notfication bar here -->
  <NotificationBar v-if="true" :notification_text="notification.notification_text" :notification_class="notification.notification_class"></NotificationBar>
  <!-- ends here -->

  <vue-topprogress ref="topProgress" color="pink" height:=4></vue-topprogress>
  <div class="container mt-5">

    <div class="row">
      <div class="col-md-12">
        <div class="row">
            <div class="col-md-12 mt-5 text-center">
              <h3>Let's Make Your Store <i class="far fa-stars" style="color:orange;"></i> Beautiful !</h3>
            </div>
        </div>
        <br/>
        <!-- each font here -->
        <div class="row mt-3">
            <div class="col-md-4"></div>
            <div class="col-md-4 mb-3">
              <!-- Pricing Table Here -->
              <div class="card-deck mb-3">
              <div class="card mb-4 box-shadow">
                <div class="card-header">
                  <h4 class="my-0 font-weight-normal text-center"><i class="fad fa-rocket-launch" style="color:red;"></i> Premium</h4>
                </div>
                <div class="card-body">
                  <h1 class="card-title text-center"><strike><small style="color:green;">$19.99</small></strike> $9.99 <small class="text-muted" style="font-size: 30px;">One Time</small></h1>
                  <ul class="mt-4 mb-4">
                    <li><b>Unlimited Custom Fonts </b></li>
                    <li><b>Unlimited Google Fonts</b></li>
                    <li><b>Add Custom Styles</b></li>
                    <li><b>Apply to Specific Themes</b></li>
                    <li><b>24/7 Priority Support</b></li>
                  </ul>
                  <button type="button" class="btn btn-lg w-100 btn-default btn-grad" @click="onUpgrade">Upgrade</button>
                  <br/>
                  <small class="text-muted"></small>
                </div>
              </div>
            </div>
            <!-- ends here -->
          </div>

        </div>
        <!-- ends here -->

        <div class="row mt-2">
          <h2 class="text-center">What <i style="color:yellow;" class="fad fa-smile"></i> People Say About Us ?</h2>
        </div>

          <!-- reviews go here -->
              <div class="card-body mt-4 mb-4 align-middle">

                <b>Big Fan of the App! We had one issue which was fixed by Matthew within a couple of hours. I would definitely use it again!</b>
                <b>&#9733;&#9733;&#9733;&#9733;&#9733;</b>
                <br>
                <b>Matthew was great and helped within 30mins to have this fixed for me.</b>
                <b>&#9733;&#9733;&#9733;&#9733;</b>
                <br>
                <b>Simply the best. Easy to install and integrate.</b>
                <b>&#9733;&#9733;&#9733;&#9733;&#9733;</b>
                <br>
                <b>Does exactly what I needed for a reasonable cost!</b>
                <b>&#9733;&#9733;&#9733;&#9733;&#9733;</b>

              </div>
                <!-- <div class="row mt-5 card">
                <img  src="../../assets/one.jpg" alt="Card image cap">
                </div>
                <br/>
                <div class="row mt-2 card">
                <img  src="../../assets/two.jpg" alt="Card image cap">
                </div>
                <br/>
                <div class="row mt-2 card">
                <img  src="../../assets/three.jpg" alt="Card image cap">
                </div>
                <br/> -->

              <!-- ends here -->


      </div>
    </div>
  </div>
</div>
</template>

<script>

import { vueTopprogress } from 'vue-top-progress'
import { upgradePlanAPI , activatePlanAPI } from '../../service/ChargeService.js'

import NotificationBar from '../utils/NotificationBar.vue'

//config here
const { API_URL } = require('../../../config.js');


export default {
  name: 'Upgrade',
  data:function(){
    return {
      shop_url:'',
      shop_token:'',
      notification:{
        notification_text:'',
        notification_class:'',
        isNotification:false,
      },
    }
  },
  components: {
     vueTopprogress,
     NotificationBar,
  },
  methods:{

    notify:function(flag , notification_class = '' , notification_text = ''){

      this.notification.isNotification = flag;
      this.notification.notification_text = notification_text;
      this.notification.notification_class = notification_class;

    },
    onUpgrade: function(){
        //plan to upgrade here && redirect to confirmation_url
        upgradePlanAPI(this.shop_url , this.shop_token)
        .then((data) => {

            //check if there is error (invalid access token)
            if(data.code && data.code == 400){
              window.location = `${API_URL}/logout`
            }


            if(!data.status && data.message){
              alert(data.message);
            }else{
              const confirmation_url = data.charge['application_charge']['confirmation_url'];
              window.location.href = confirmation_url;
            }
        });
    }
  },
  mounted(){

      this.shop_url = document.getElementById('shop_domain').value
      this.shop_token = document.getElementById('shop_token').value


      // this.shop_url = 'shristorey.myshopify.com'
      // this.shop_token = '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'




      const charge_id = this.$route.query.charge_id
      if(charge_id){
          //do check if the payment is really made or this one is just fake
          activatePlanAPI(this.shop_url , this.shop_token , charge_id)
          .then((data)=>{

            //check if there is error (invalid access token)
            if(data.code && data.code == 400){
              window.location = `${API_URL}/logout`
            }


            if(!data.status && data.message){
              alert(data.message)
            }else{

                this.notify(true,'alert alert-success','Your account has been upgraded');

                //show notified message here
                setTimeout(function(scope){
                    scope.notify(false);
                    window.location = `${API_URL}/dashboard`

                },1000,this);


            }
          });
      }

      this.$refs.topProgress.start();
      // Use setTimeout for demo
      setTimeout(() => {
        this.$refs.topProgress.done()
      }, 1000);

  }
}
</script>

<style>


.btn-grad {background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%)}
         .btn-grad {
            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            background-size: 200% auto;
            color: white;
            box-shadow: 0 0 20px #eee;
            border-radius: 10px;
            display: block;
          }

  .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

</style>
