<template>
  <div>


    <!-- notfication bar here -->
    <NotificationBar v-if="true" :notification_text="notification.notification_text"
                     :notification_class="notification.notification_class"></NotificationBar>
    <!-- ends here -->


    <vue-topprogress ref="topProgress" color="pink" height:=4></vue-topprogress>
    <div class="container mt-5">
      <!-- greeting starts here -->
      <div class="row">
        <div class="col-md-6">
          <h3 style="color: black" v-if="store.plan === 'free'">Upgrade to premium to add custom fonts</h3>
          <router-link v-if="store.plan === 'free'" to="/upgrade" class="btn btn-default btn-sale"
                       style="color:white;background-color:black;font-weight:bold;"><i
              class="fad fa-rocket-launch"></i>
            Premium Flash Sale @ <strike>19.99</strike> $9.99 One Time Only
          </router-link>
        </div>
        <div class="col-md-6 text-center">
          <h6>Contact Us? -> <a href="mailto: fontio@bysimpli.com?subject=I need some help with Fontio">
            fontio@bysimpli.com</a>
          </h6>
          <h6>
            {{
              store.plan === 'free' ? 'Help keep Fontio free with a ⭐⭐⭐⭐⭐️ review' : 'Say thanks with a ⭐⭐⭐⭐⭐️ review'
            }}
            -> <a href="https://apps.shopify.com/font-io">Here</a></h6>
        </div>
      </div>
      <!-- ends here -->
      <!-- themes div-->
      <div>
        <div class="col-md-2">
          <select v-if="this.store_themes.length > 0" class="form-select form-select-lg mb-3"
                  @change="changeTheme"
                  :disabled="(store.plan === 'free' && store.shopify_plan !=='affiliate')"
                  :data-toggle="(store.plan === 'free' && store.shopify_plan !=='affiliate' ? 'tooltip' : '')"
                  :title="(store.plan === 'free' && store.shopify_plan !=='affiliate' ? 'Upgrade to premium to use this feature' : '')">
            <option v-for="theme,index in store_themes" :key="index" :value="theme.id"
                    :selected="(theme.role === 'main')">
              {{ theme.name }}
            </option>
          </select>
        </div>

      </div>
      <div class="row">
        <div class="col-md-7">
          <div class="row">
            <div class="col-md-12 mt-3 text-center">
              <h3>All Fonts</h3>
            </div>
          </div>
          <hr/>
          <clip-loader :loading="loader.hasFontsLoaded" :color="color" class="mb-3"></clip-loader>


          <div v-if="!fonts || !fonts.length && !loader.hasFontsLoaded" class="row mt-5">
            <div class="col-md-12 text-center">
              <p>No Fonts Added!</p>
            </div>
          </div>


          <!-- each font here -->
          <div v-for="font,index in fonts" :key="index" class="row">
            <div class="col-md-12 mb-3 text-center">

              <div class="card btn-grad">
                <div class="card-body">
                  <h5 class="card-title text-truncate" style="max-width: 190px;">{{ font.name }}</h5>
                  <a style="color:white; border:1px solid pink;" class="btn btn-default"
                     @click="deleteFont(font.id)"><i
                      class="fas fa-trash-alt"></i></a>
                </div>
              </div>

            </div>
          </div>
          <!-- ends here -->

        </div>
        <div class="col-md-5">
          <div class="row">
            <div class="col-md-12 mt-3 mb-3 text-center">
              <h3>Upload Fonts {{ store.plan }} </h3>
            </div>
          </div>
          <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              :options="dropzoneOptions"
              @vdropzone-success="onFontUpload"
              @vdropzone-sending="onFontAdded"
          ></vue-dropzone>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {vueTopprogress} from 'vue-top-progress'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

//loader vue -spinner
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

//config here
const {API_URL} = require('../../../config.js');

import {removeCustomFontAPI} from '../../service/FontService.js'


import NotificationBar from '../utils/NotificationBar.vue'
import {getStoreInfoAPI} from "@/service/StoreService";
import {changeThemeAPI, getThemesAPI} from "../../service/ThemeService";

export default {
  name: 'FontUpload',
  data: function () {
    return {
      fonts: [],
      store_themes: [],
      shop_url: '',
      shop_token: '',
      color: 'red',
      store: {
        plan: '',
        theme_id: ''
      },
      dropzoneOptions: {
        url: `${API_URL}/api/font`,
        thumbnailWidth: 150,
        maxFilesize: 15.0,
        uploadMultiple: true,
        addRemoveLinks: true,
      },
      loader: {
        hasFontsLoaded: false
      },
      notification: {
        notification_text: '',
        notification_class: '',
        isNotification: false,
      },
    }
  },
  components: {
    vueTopprogress,
    vueDropzone: vue2Dropzone,
    ClipLoader,
    NotificationBar
  },
  methods: {
    changeTheme: function (event) {
      if (event.target.value !== '') {
        this.store.theme_id = event.target.value;
        this.getFonts(event.target.value);
      }
    },
    notify: function (flag, notification_class = '', notification_text = '') {
      this.notification.isNotification = flag;
      this.notification.notification_text = notification_text;
      this.notification.notification_class = notification_class;
    },

    deleteFont: function (font_id) {

      removeCustomFontAPI(this.shop_url, this.shop_token, font_id)
          .then((data) => {

            //check if there is error (invalid access token)
            if (data.code && data.code == 400) {
              window.location = `${API_URL}/logout`
            }

            if (data.status) {
              //if the font is successfully deleted here
              this.getFonts(this.store.theme_id) // reload the fonts here
            }


          });


    },
    onFontAdded: function (file, xhr, formData) {

      formData.append('domain', this.shop_url);
      formData.append('access_token', this.shop_token);
      formData.append('type', 'custom-font');
      formData.append('theme_id', this.store.theme_id);

    },
    onFontUpload: function (file, response) {

      if (!response.status) {
        this.notify(true, 'alert alert-warning', `<b>${response.message}</b>`);
        return;
      }

      if (response.code && response.code == 400) {
        window.location = `${API_URL}/logout`
      }

      console.log('File has been uploaded', file);
      console.log('File has been uploaded', response);


      this.notify(false);


      //load the fonts again after posting
      this.getFonts(this.store.theme_id)

    },
    //utility for getting fonts
    getFonts: function (theme_id) {

      //show when fonts are loading
      this.loader.hasFontsLoaded = true;
      let url = `${API_URL}/api/font?domain=${this.shop_url}&type=custom_font&is_active=active&theme_id=${theme_id}`;
      fetch(url, {
        method: 'GET',
      })
          .then(response => response.json())
          .then((data) => {
            //get the fonts here
            this.fonts = data.fonts;
            this.loader.hasFontsLoaded = false; //hide when they are completely loaded

          });

    }
  },
  mounted() {


    this.shop_url = document.getElementById('shop_domain').value
    this.shop_token = document.getElementById('shop_token').value


    // this.shop_url = 'shristorey.myshopify.com'
    // this.shop_token = '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'
    getStoreInfoAPI(this.shop_url, this.shop_token)
        .then((data) => {
          const store_info = data;
          this.store.plan = store_info['store']['plan'];
          this.store.theme_id = store_info['store']['theme'];
          //get the list of all the fonts on load
          this.getFonts(this.store.theme_id)
          // added condition, if store is on development mode then all features will be available
          if (store_info['store']['plan'] === 'free' && store_info['store']['shopify_plan_name'] !== 'affiliate') {
            document.getElementById('dropzone').setAttribute("style", "pointer-events:none;");
          }
        });
    getThemesAPI(this.shop_url, this.shop_token)
        .then((data) => {
          if (data['themes'] && data['themes'].length > 0) {
            this.store_themes = data['themes'];
            for (let theme of this.store_themes) {
              if (theme.role === 'main') {
                this.theme_id = theme.id;
                break;
              }
            }
            //update theme_id at the backend here
            changeThemeAPI(this.shop_url, this.shop_token, this.theme_id)
          }
        });

    this.$refs.topProgress.start();
    // Use setTimeout for demo
    setTimeout(() => {
      this.$refs.topProgress.done()
    }, 1000);

  }
}
</script>

<style>


.btn-grad {
  background-image: linear-gradient(to right, #4776E6 0%, #8E54E9 51%, #4776E6 100%)
}

.btn-grad {
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


</style>
