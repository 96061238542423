const { API_URL } = require('../../config.js');

function getThemesAPI(shop_url , shop_token){

	let ACTIVATION_API_URL =  `${API_URL}/api/theme?domain=${shop_url}&access_token=${shop_token}`;
	return fetch(ACTIVATION_API_URL)
	.then((data) => data.json())

}

function changeThemeAPI(shop_url , shop_token, theme_id){

	let THEME_API_URL = `${API_URL}/api/theme`
	let data = {
		'domain':shop_url,
		'theme_id':theme_id,
		'access_token':shop_token,
	}
	return fetch(THEME_API_URL, {
			'method':'PUT',
			headers: {
					'Content-type': 'application/json; charset=UTF-8' 
				},
				body: JSON.stringify(data)
			})
			.then(response => response.json());
}


function saveThemeAPI(domain , access_token , theme_id){

	const THEME_API_URL = `${API_URL}/api/theme/add`;

	let data = {
		'domain':domain,
		'theme_id':theme_id,
		'access_token':access_token,
	};
	
	return fetch(THEME_API_URL,{
		method:'POST',
		headers:{
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data) 
	})
	.then((data) => data.json())

}


module.exports = {
	getThemesAPI,
	changeThemeAPI,
	saveThemeAPI
}