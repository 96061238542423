const {API_URL} = require('../../config.js');

const ELEMENT_API_URL = `${API_URL}/api/element`

function saveFontAPI(domain, type, access_token, theme_id, options) {

    let data = {
        'domain': domain,
        'type': type,
        'access_token': access_token,
        'theme_id': theme_id,
        'options': [
            options[0],
            options[1],
            options[2],
            options[3],
            options[4],
            options[5]
        ]
    };
    return fetch(ELEMENT_API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
        .then((data) => data.json())

}


/** utility function for removing elements i.e (H1, H2...etc) */
function removeFontAPI(domain, element_name, access_token, theme_id) {
    let data = {
        'domain': domain,
        'access_token': access_token,
        'element_name': element_name,
        'theme_id': theme_id
    };
    return fetch(ELEMENT_API_URL, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
        .then((data) => data.json())
}


/** utility function for removing fonts */
function removeCustomFontAPI(domain, access_token, font_id) {
    const FONT_API_URL = `${API_URL}/api/font`
    let data = {
        'domain': domain,
        'access_token': access_token,
        'font_id': font_id
    };
    return fetch(FONT_API_URL, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
        .then((data) => data.json())
}


/* ends here */


function getFontAPI(domain, type, theme_id) {
    return fetch(ELEMENT_API_URL + `?domain=${domain}&type=${type}&theme_id=${theme_id}`)
        .then((data) => data.json())

}


function saveCustomFontAPI(domain, type, access_token, theme_id, custom_elements, font_name, font_src, font_size, font_length) {

    const ELEMENT_API_URL = `${API_URL}/api/element/custom`

    let data = {
        'domain': domain,
        'type': type,
        'access_token': access_token,
        'theme_id': theme_id,
        'custom_elements': custom_elements,
        'font_name': font_name,
        'font_src': font_src,
        'font_size': font_size,
        'font_length': font_length
    };

    return fetch(ELEMENT_API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })
        .then((data) => data.json())

}

module.exports = {
    saveFontAPI,
    removeFontAPI,
    getFontAPI,
    removeCustomFontAPI,
    saveCustomFontAPI
} 
