<template>
  <div>
    <nav class="navbar navbar-expand-lg color-header">
  <div class="container-fluid">
    <a class="navbar-brand text-style" href="#"> Fontio</a>
    <button  class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon" style="color:black;"><i class="fad fa-bars"></i></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link  class="nav-link active text-style"  to="/dashboard"> Dashboard</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/fonts" class="nav-link text-style">Custom Fonts</router-link>
        </li>
        <!-- <li class="nav-item">
          <router-link to="/premium" class="nav-link text-style" ><i class="fad fa-rocket-launch"></i> My Tags/Classes</router-link>
        </li> -->
        <li class="nav-item">
          <a href="https://bysimpli.com/fontio/" target="_blank" class="nav-link text-style" >  Help</a>
        </li>
        <li class="nav-item">
          <router-link to="/settings" class="nav-link text-style" > Settings</router-link>
        </li>
        <li  v-if=" plan === 'free' " class="nav-item text-style">
          <router-link to="/upgrade" class="btn btn-default btn-sale" style="color:black;font-weight:bold;"> <i class="fad fa-rocket-launch"></i> Premium Flash Sale @ <strike>19.99</strike> $9.99 One Time Only</router-link>
        </li>
      </ul>
    </div>
  </div>
</nav>
  </div>
</template>

<script>

export default {
  name: 'Header',
  props:['plan'],
  methods:{
  }
}

</script>


<style scoped>


    .text-style{
      color:white;
    }

 .color-header{
    background: #fffbd5;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #b20a2c, #fffbd5);  /* Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(to right, #b20a2c, #fffbd5); */
    background:#0D6EFD;
    border: 2px solid black;
}

 .btn-grad {background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%)}
         .btn-grad {
            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            background-size: 200% auto;
            color: white;
            box-shadow: 0 0 20px #eee;
            border-radius: 10px;
            display: block;
          }

  .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  .btn-sale{
    background: #FFC107;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
  }

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>