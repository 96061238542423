<template>
  <div>
    <div :class="notification_class" role="alert">
        <small v-html="notification_text"></small>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotificationBar',
  props:["notification_text","notification_class"],
  methods:{
    
  }
}

</script>


<style scoped>


</style>
