<template>
  <div>


    <!-- notfication bar here -->
    <NotificationBar v-if="true" :notification_text="notification.notification_text"
                     :notification_class="notification.notification_class"></NotificationBar>
    <!-- ends here -->

    <vue-topprogress ref="topProgress" color="pink" :height=4></vue-topprogress>
    <template>
      <!-- Modal -->
      <div class="modal fade" id="myModal" role="dialog">
        <div class="modal-dialog">

          <!-- Modal content-->
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <h4 class="modal-title">Modal Header</h4>
            </div>
            <div class="modal-body">
              <p>Some text in the modal.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <v-container fluid>
      <v-row align="center">

        <!-- Dashboard here -->
        <div class="container mt-5">
          <!-- greeting starts here -->
          <div class="row">
            <div class="col-md-6">
              <h6>
                Preview - You are editing fonts for {{ this.shop_url }}
              </h6>
              <div v-if="selectedValue.length > 0" id="font_sample_text">
                You are editing:
                <span>
                  {{ removeComma() }}
                </span>
              </div>
              <div v-else>
                Edit a tag or add a custom tag to start
              </div>
            </div>
            <div class="col-md-6 text-center">
              <h6>Contact Us ? -> <a href="mailto: fontio@bysimpli.com?subject=I need some help with Fontio">
                fontio@bysimpli.com</a></h6>
              <h6>
                {{
                  store.plan === 'free' ? 'Help keep Fontio free with a ⭐⭐⭐⭐️ review' : 'Say thanks with a ⭐⭐⭐⭐⭐️ review'
                }}
                -> <a href="https://apps.shopify.com/font-io">Here</a></h6>
            </div>
          </div>
          <!-- ends here -->

          <div class="row mt-5">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-2">
                  <select v-if="this.store_themes.length > 0" class="form-select form-select-lg mb-3"
                          @change="changeTheme"
                          :disabled="(store.plan === 'free' && store.shopify_plan !=='affiliate')"
                          :data-toggle="(store.plan === 'free' && store.shopify_plan !=='affiliate' ? 'tooltip' : '')"
                          :title="(store.plan === 'free' && store.shopify_plan !=='affiliate' ? 'Upgrade to premium to use this feature' : '')">
                    <option v-for="theme,index in store_themes" :key="index" :value="theme.id"
                            :selected="(theme.role === 'main')">
                      {{ theme.name }}
                    </option>
                  </select>
                </div>
                <div class="col-md-3">
                </div>
                <div class="col-md-3 mt-1">
                  <v-col v-if="addCustomField">
                    <input type="text" class="form-control" placeholder="Add Custom Tag Here" v-model="customClasses">
                  </v-col>
                </div>
                <div class="col-md-4 mt-3 ml-5">
                  <v-btn class="text-white pa-3 font-weight-semibold" @click="addCustom" style="background: #0D6EFD;"
                         :disabled="(store.plan === 'free' && store.shopify_plan !=='affiliate')">
                    Add Custom Tag
                  </v-btn>
                </div>

              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-2 mt-1">
                      <select v-if="editItems || this.addCustomField" class="form-select form-select-lg mb-3"
                              @change="typeSelect"
                              v-model="action.selected_type">
                        <option selected value="">Select Font Type</option>
                        <option value="google_font">Google Font</option>
                        <option value="custom_font">Custom Font</option>
                      </select>
                    </div>
                    <div class="col-md-2 mt-1">
                      <!-- for google fonts -->
                      <select v-if="isGoogleFont && editItems" class="form-select form-select-lg mb-3"
                              @change="fontSelect">
                        <option selected value="">Select Font</option>
                        <option v-for="font,index in fonts" :key="index" v-bind:value="index">{{ font.family }}</option>
                      </select>
                      <!-- ends here -->

                      <!-- for custom fonts -->
                      <select v-if="!isGoogleFont && editItems" class="form-select form-select-lg mb-3"
                              @change="fontSelect">
                        <option selected value="">Select Font</option>
                        <option v-for="font in fonts" :key="font.id" v-bind:value="font.id">{{ font.name }}</option>
                      </select>
                      <!-- ends here -->
                    </div>
                    <div class="col-md-2 mt-1" v-if="isGoogleFont">
                      <!-- only for google fonts -->
                      <div class="mb-3" v-if="editItems || this.addCustomField">
                        <select class="form-select form-select-lg mb-3" @change="variantSelect">
                          <option selected value="">Select Variant</option>
                          <option v-for="variant, index in variants" :key="index" value:="variant">{{ variant }}
                          </option>
                        </select>
                      </div>
                      <!-- ends here -->
                    </div>
                    <div class="col-md-2 mt-1">
                      <!-- for font size -->
                      <div class="mb-3" v-if="editItems || this.addCustomField">
                        <select class="form-select form-select-lg mb-3" @change="fontSelectSize">
                          <option selected value="">Select Font Size</option>
                          <option v-for="size, index in font_sizes" :key="index" value:="size">{{ size }}</option>
                        </select>
                      </div>
                      <!-- ends here -->
                    </div>
                    <div class="col-md-2 mt-1">
                      <!-- for font length -->
                      <div class="mb-3">
                        <select v-if="editItems || this.addCustomField" class="form-select form-select-lg mb-3"
                                @change="fontLengthSelect">
                          <option value="px">Px</option>
                          <option value="rem">Rem</option>
                        </select>
                      </div>
                      <!-- ends here -->
                    </div>

                    <div class="col-md-2 mt-2" v-if="editItems || this.addCustomField">
                      <!-- <button @click="addFont" class=" btn-grad btn">Add Custom Tag</button> -->
                      <v-btn class="text-white pa-3 font-weight-semibold" style="background: #0D6EFD;"
                             @click="saveFont">
                        Save
                      </v-btn>

                    </div>
                    <div class="col-md-2 mr-4 mt-2" v-if="isIncomplete">
                      <v-btn class="pa-3 font-weight-semibold"
                             style="color: rgb(210 105 115);right:53%;background: #F8D7DA;">
                        Incomplete
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Ends here -->
        <!-- Dashboard Continued -->
        <div class="container mt-5">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="table-responsive">
                  <table class="table" id="table">
                    <thead>
                    <tr>
                      <th scope="col">&nbsp;&nbsp;Tag</th>
                      <th scope="col">Font Type</th>
                      <th scope="col">Font</th>
                      <th scope="col">Variant</th>
                      <th scope="col">Font Size</th>
                      <th scope="col">Font Length</th>
                      <th scope="col">Edit</th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <template v-for="element in elements">
                      <!-- This tag won't display but help you to nest two foreach -->
                      <tbody v-for="(data,index) in element" :key="index">
                      <tr>
                        <td @click="editItem( data.tag )" style="cursor: pointer"><input type="checkbox" :id="data.tag">&nbsp;&nbsp;{{
                            data.tag
                          }}
                        </td>
                        <td @click="editItem( data.tag )" style="cursor: pointer">
                          {{
                            data.element_type !== 'None' ? data.element_type === 'basic_element' ? 'Google Font' : 'Custom Font' : 'None'
                          }}
                        </td>
                        <td @click="editItem(data.tag)" style="cursor: pointer">{{ data.font }}</td>
                        <td @click="editItem(data.tag)" style="cursor: pointer">{{
                            data.variant ? data.variant : 'None'
                          }}
                        </td>
                        <td @click="editItem(data.tag)" style="cursor: pointer">
                          {{ data.font_size ? data.font_size : 'None' }}
                        </td>
                        <td @click="editItem(data.tag)" style="cursor: pointer">
                          {{ data.font_length ? data.font_length : 'None' }}
                        </td>
                        <td><a href="#" @click="editItem(data.tag)">Edit</a></td>
                        <td><a href="#" @click="removeFont(data.tag)">Delete</a></td>
                      </tr>
                      </tbody>
                    </template>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ends here -->
      </v-row>
    </v-container>
  </div>

</template>


<script>

import {vueTopprogress} from 'vue-top-progress'
import Vue from 'vue';

import {getFontAPI, removeFontAPI, saveCustomFontAPI, saveFontAPI} from '../../service/FontService.js'

import NotificationBar from '../utils/NotificationBar.vue'

import {getStoreInfoAPI} from '../../service/StoreService.js'
import {changeThemeAPI, getThemesAPI} from "@/service/ThemeService";


//config here
const {API_URL} = require('../../../config.js');
var e7 = [];
export default {
  name: 'Dashboard',
  data: function () {
    return {
      fonts: [],
      e7,
      theme_id: '',
      store_themes: [],
      addCustomField: false,
      selectedValue: [],
      editItems: false,
      isIncomplete: false,
      customClasses: '',
      font_sizes: ['default'],
      font_length: 'px',
      variants: [],
      elements: [],
      color: 'red',
      shop_url: '',
      shop_token: '',
      isGoogleFont: true,
      action: {
        selected_font: '',
        selected_tag: [],
        selected_type: '',
        selected_variant: '',
        sample_text: ''

      },
      store: {
        plan: 'free',
        shopify_plan: ''
      },
      flag: {
        is_visible: false,
      },
      loader: {
        hasFontsLoaded: false,
        hasFontsaved: false,
      },
      notification: {
        notification_text: '',
        notification_class: '',
        isNotification: false,
      },


    }
  },
  components: {
    vueTopprogress,
    NotificationBar,
  },
  methods: {
    changeTheme: function (event) {
      if (event.target.value !== '') {
        this.theme_id = event.target.value;
        getFontAPI(this.shop_url, 'basic_element', event.target.value)
            .then((data) => {
              this.loader.hasFontsLoaded = false;
              this.elements = data.elements;
            })
      }
    },
    resetAction: function () {
      this.action.selected_tag = [];
      this.action.selected_font = '';
      this.action.selected_type = '';
      this.action.selected_variant = '';
      this.action.selected_font_size = '';
      this.action.sample_text = '';
    },
    editItem(item) {
      if (document.getElementById(`${item}`).checked) {
        if (this.selectedValue.indexOf(item) !== -1) {
          console.log('already exists')
        } else {
          this.selectedValue.push(item);
          this.action.selected_tag.push(item);
        }
        this.editItems = true;
      } else {
        if (!document.getElementById(`${item}`).checked) {
          if (this.action.selected_tag.indexOf(`${item}`) !== -1) {
            const index = this.selectedValue.indexOf(item);
            this.selectedValue.splice(index, 1);
            const index_ = this.action.selected_tag.indexOf(item);
            this.action.selected_tag.splice(index_, 1);
          } else {
            document.getElementById(`${item}`).checked = true;
            this.selectedValue.push(item);
            this.editItems = true;
            this.action.selected_tag.push(item);
          }
        }
      }
    },
    addCustom: function () {
      this.addCustomField = true;
      this.editItems = true;
    },
    removeComma: function () {
      return this.selectedValue.join()
    },

    notify: function (flag, notification_class = '', notification_text = '') {

      this.notification.isNotification = flag;
      this.notification.notification_text = notification_text;
      this.notification.notification_class = notification_class;

    },

    fontSelect: function (event) {

      //check if fonts are selected

      if (this.isGoogleFont) {

        //if anything is selected (from google font)
        this.action.selected_font = event.target.value;

        if (this.action.selected_font)
          this.variants = this.fonts[this.action.selected_font].variants;

      } else {

        //if anything is selected (from custom font)
        this.action.selected_font = this.fonts.filter((font) => font.id == event.target.value)[0]
        this.variants = []
      }

    },
    fontLengthSelect: function (event) {
      // set font length here
      if (event.target.value === 'rem') {
        this.font_length = 'rem'
      }

    },

    variantSelect: function (event) {

      this.action.selected_variant = event.target.value;
      this.previewFont();
    },

    fontSelectSize: function (event) {
      this.action.selected_font_size = event.target.value;
    },

    //utility to generateFont URL
    generateFontURL(font, variant) {
      let FONT_URL = 'https://fonts.googleapis.com/css2?family=' + font.split(' ').join('+');
      let isnum = /^\d+$/.test(variant);
      if (isnum) {
        FONT_URL += `:wght@${variant}&display=swap`;
      } else {
        //case if it is italic or regular
        if (variant === 'regular') {
          FONT_URL += `&display=swap`
        } else if (variant == 'italic') {
          FONT_URL += `:ital@1&display=swap`;
        } else {
          //all combined variants are here
          const weight = variant.replace(/[^0-9]/g, '');
          FONT_URL += `:ital,wght@1,${weight}&display=swap`;
        }
      }
      return FONT_URL;
    },

    //utility to preview fonts
    previewFont() {
      if (!this.action.selected_font) {
        //if font has not been selected
        this.isIncomplete = true;
        return;
      }

      if (this.isGoogleFont) {

        const font = this.fonts[this.action.selected_font].family;
        const variant = this.action.selected_variant;

        if (!this.action.selected_variant) {
          //if font has not been selected
          this.isIncomplete = true;
          return;
        }

        //if google font
        const url = this.generateFontURL(font, variant)
        //check if id already exists
        var font_id = document.getElementById("google-font");
        if (font_id) {
          //just update the href
          font_id.href = url
        } else {
          //add the font the head here
          var head = document.getElementsByTagName('head')[0];
          var link = document.createElement('link');
          link.type = 'text/css';
          link.rel = 'stylesheet';
          link.href = url;
          link.id = "google-font";
          head.appendChild(link);
        }
        //assign the appended font
        var sample_text = document.getElementById('font_sample_text');
        sample_text.style.fontFamily = `${font}`

      } else {

        const data = this.action.selected_font;
        const url = data['src']
        const name = data['name']

        fetch(`${url}`)
            .then(resp => resp.arrayBuffer())
            .then(font => {
              const fontFace = new FontFace(`${name}`, font);
              document.fonts.add(fontFace);
              var sample_text = document.getElementById('font_sample_text');
              sample_text.style.fontFamily = `${name}`
            })

        //just handle with style attribute here
      }


      this.notify(false);

    },

    //utility to save fonts
    saveFont: function () {
      let font_type = '', font = '', tag = '', src = '', size = '', font_variant = '', font_length = '';


      if (!this.action.selected_font) {
        //if font has not been selected
        this.isIncomplete = true;
        return;
      }

      if (this.isGoogleFont) {

        //check if everything is proper here

        if (!this.action.selected_variant) {
          //if font has not been selected
          this.isIncomplete = true;
          return;
        }
        if (!this.action.selected_font_size) {
          //if font has not been selected
          this.isIncomplete = true;
          return;
        }
        //ends here
        let custom_classes = this.customClasses.trim().split('\n');
        custom_classes = custom_classes.filter(item => item);
        //if saved will be a google font
        font = this.fonts[this.action.selected_font].family;
        const variant = this.action.selected_variant;
        tag = custom_classes.length > 0 ? custom_classes : this.action.selected_tag;
        src = this.generateFontURL(font, variant);
        font_type = 'google_font';
        size = this.action.selected_font_size
        font_variant = this.action.selected_variant
        this.loader.hasFontsaved = true;
        saveFontAPI(this.shop_url, font_type, this.shop_token, this.theme_id, [tag, font, src, size, font_variant, this.font_length]).then((data) => {

          //check if there is error (invalid access token)
          if (!data.status) {
            this.notify(true, 'alert alert-warning', `<b>${data.message}</b>`);
            return;
          }
          if (data.code && data.code === 400) {
            window.location = `${API_URL}/logout`
          }
          //hide the font bar
          this.flag.is_visible = false;
          Vue.$toast.default('Font Saved Successfully.', {
            // optional options Object
            duration: 1000,
          });
          this.loader.hasFontsLoaded = true;
          getFontAPI(this.shop_url, 'basic_element', this.theme_id)
              .then((data) => {
                //check if there is error (invalid access token)
                if (data.code && data.code === 400) {
                  window.location = `${API_URL}/logout`
                }
                this.elements = data.elements;
                //close all notifications here
                this.notify(false);
                this.selectedValue = []
                window.location.reload();
                //reset all these here
                this.resetAction();
                this.loader.hasFontsLoaded = false;
                this.loader.hasFontsaved = false;
              })

        });

      } else {
        const data = this.action.selected_font;
        let custom_classes = this.customClasses.trim().split('\n');
        custom_classes = custom_classes.filter(item => item);
        font = data.f_hash
        tag = custom_classes.length > 0 ? custom_classes : this.action.selected_tag;
        src = data.src
        size = this.action.selected_font_size
        font_length = this.font_length
        font_type = 'custom_font';
        this.loader.hasFontsaved = true;
        saveCustomFontAPI(this.shop_url, font_type, this.shop_token, this.theme_id, tag, font, src, size, font_length)
            .then((data) => {

              console.log('Added all elements' + data);

              Vue.$toast.default('Elements Saved Successfully.', {
                // optional options Object
                duration: 1000,
              });
              this.loader.hasFontsLoaded = true;
              getFontAPI(this.shop_url, 'basic_element', this.theme_id)
                  .then((data) => {


                    //check if there is error (invalid access token)
                    if (data.code && data.code === 400) {
                      window.location = `${API_URL}/logout`
                    }

                    this.flag.is_visible = false;

                    this.elements = data.elements;
                    //close all notifications here
                    this.notify(false);
                    this.selectedValue = [];
                    window.location.reload();
                    //reset all these here
                    this.resetAction();
                    this.loader.hasFontsLoaded = false;
                    this.loader.hasFontsaved = false;
                  })
            });
      }
    },

    removeFont: function (tag) {

      this.loader.hasFontsLoaded = true;
      removeFontAPI(this.shop_url, tag, this.shop_token, this.theme_id).then((data) => {

        //check if there is error (invalid access token)
        if (data.code && data.code === 400) {
          window.location = `${API_URL}/logout`
        }

        Vue.$toast.default('Font Deleted Successfully.', {
          // optional options Object
          duration: 1000,
        });

        window.location.reload();

      });

    },

    typeSelect: function () {

      this.fonts = []


      if (!this.action.selected_type) {
        //do not show the screen here
        this.resetAction();
        //reset all the settings here
        return;
      }

      //reset the selected tags here
      this.action.selected_variant = '';
      this.action.selected_font = '';

      //ends here

      if (this.action.selected_type === 'google_font') {
        this.isGoogleFont = true;
        //load google fonts here
        //do the API call here to get all the fonts
        let url = 'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDnahbcJG1KvljUOxVcE9ERmP1q2oMc4Q8';
        fetch(url)
            .then(response => response.json())
            .then((data) => {
              this.fonts = data.items.reduce((a, x, index) => {
                a[index] = {'family': x.family, 'variants': x.variants};
                return a;
              }, {})
            });

      } else {

        if (this.store.plan === 'free' && this.store.shopify_plan !== 'affiliate') {
          document.getElementById('font_sample_text').innerHTML = '<h3 class="mt-2 mb-5">Lets Upgrade Your Account <i class="fad fa-heart" style="color:red;"></i></h3><center><a href="/upgrade" class="btn  btn-default btn-lg upgrade-btn"><i class="fad fa-rocket-launch"></i>UPGRADE MY ACCOUNT @ $9.99 Only</a></center>'
          return;
        }
        this.isGoogleFont = false;
        //load custom fonts here
        let url = `${API_URL}/api/font?domain=${this.shop_url}&type=custom_font&is_active=active&theme_id=${this.theme_id}`;
        fetch(url)
            .then(response => response.json())
            .then((data) => {
              this.fonts = data.fonts;
            });


      }
    }

  },
  mounted() {

    //do a request for loading elemens

    this.shop_url = document.getElementById('shop_domain').value
    this.shop_token = document.getElementById('shop_token').value

    // this.shop_url = 'shristorey.myshopify.com'
    // this.shop_token = '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'


    //get current theme of the store
    getStoreInfoAPI(this.shop_url, this.shop_token)
        .then((data) => {
          const store_info = data;
          this.store.plan = store_info['store']['plan'];
          this.store.shopify_plan = store_info['store']['shopify_plan_name'];
          this.theme_id = store_info['store']['theme'];
          // get fonts based on theme_id
          getFontAPI(this.shop_url, 'basic_element', `${this.theme_id}`)
              .then((data) => {
                this.loader.hasFontsLoaded = false;
                this.elements = data.elements;
              })
        });

    function addFontSizes(font_sizes) {
      for (let font_size_ = 1; font_size_ < 121; font_size_++) {
        font_sizes.push(font_size_)
      }
    }

    addFontSizes(this.font_sizes)

    this.loader.hasFontsLoaded = true;
    getThemesAPI(this.shop_url, this.shop_token)
        .then((data) => {
          if (data['themes'] && data['themes'].length > 0) {
            this.store_themes = data['themes'];
            for (let theme of this.store_themes) {
              if (theme.role === 'main') {
                this.theme_id = theme.id;
                break;
              }
            }
            //update theme_id at the backend here
            changeThemeAPI(this.shop_url, this.shop_token, this.theme_id)
          }
        });
    this.$refs.topProgress.start();

    // Use setTimeout for demo
    setTimeout(() => {
      this.$refs.topProgress.done()
    }, 1000);


  }
}
</script>

<style>
.upgrade-btn {
  background: #e96443;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #904e95, #e96443);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #904e95, #e96443);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  transition: transform .2s;
}

.upgrade-btn:hover {
  color: white;
  transform: scale(1.15);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  border: 2px solid black;

}

.btn-grad {
  background-image: linear-gradient(to right, #4776E6 0%, #8E54E9 51%, #4776E6 100%)
}

.btn-grad {
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.dashboard-bar {
  padding: 0 var(--p-space-6) !important;
  padding-top: 20px !important;
}

.dashboard-bar .Polaris-Box {
  background: #4DF9A7;
}

.dashboard-bar .Polaris-Icon {
  display: inline-block !important;
}

.dashboard-bar .Polaris-Icon svg {
  margin-top: 2px !important;
}

.dashboard-bar .star-rating {
  font-size: 20px !important;
  color: #ccc !important;
  display: inline-block !important;
  position: relative !important;
  cursor: pointer !important;
  margin-left: 6px !important;
}

.dashboard-bar .star-rating .fa-star {
  position: absolute !important;
  left: 0;
  top: -17px !important;
  color: black !important;
  -webkit-transition: color 200ms !important;
  -moz-transition: color 200ms !important;
  -o-transition: color 200ms !important;
  transition: color 200ms !important;
}

.dashboard-bar .star-rating .fa-star:hover,
.dashboard-bar .star-rating .fa-star.active {
  color: #f7d103 !important;
}

.dashboard-bar .star1 {
  left: 0 !important;
}

.dashboard-bar .star2 {
  left: 25px !important;
}

.dashboard-bar .star3 {
  left: 50px !important;
}

.dashboard-bar .star4 {
  left: 75px !important;
}

.dashboard-bar .star5 {
  left: 100px !important;
}
</style>
