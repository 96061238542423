const { API_URL } = require('../../config.js');

function upgradePlanAPI(shop_url , shop_token){
      const CHARGE_API_URL = `${API_URL}/api/store`;
      let data = {
        'domain':shop_url,
        'access_token':shop_token,
      };
      return fetch(CHARGE_API_URL,{
        method:'POST',
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data) 
      })
      .then((data) => data.json())
}

function activatePlanAPI(shop_url , shop_token , charge_id){
  
  let ACTIVATION_API_URL =  `${API_URL}/api/store?access_token=${shop_token}&domain=${shop_url}&charge_id=${charge_id}`;

  return fetch(ACTIVATION_API_URL)
  .then((data) => data.json())
}

module.exports = {
  upgradePlanAPI,
  activatePlanAPI
}