<template>
  <div>

    <!-- notfication bar here -->
    <NotificationBar v-if="true" :notification_text="notification.notification_text"
                     :notification_class="notification.notification_class"></NotificationBar>
    <!-- ends here -->

    <vue-topprogress ref="topProgress" color="pink" height:=4></vue-topprogress>

    <!-- Ends here -->
    <div class="container mt-5">
      <!-- greeting starts here -->
      <div class="row">
        <div class="col-md-6">
          <h3 style="color: black" v-if="store.plan === 'free'">Upgrade to premium to add fonts for custom tags or
            classes</h3>
          <router-link v-if="store.plan === 'free'" to="/upgrade" class="btn btn-default btn-sale"
                       style="color:white;background-color:black;font-weight:bold;"><i class="fad fa-rocket-launch"></i>
            Premium Flash Sale @ <strike>19.99</strike> $9.99 One Time Only
          </router-link>
        </div>
        <div class="col-md-6 text-center">
          <h6>Contact Us ? -> <a href="mailto: fontio@bysimpli.com?subject=I need some help with Fontio">
            fontio@bysimpli.com</a>
          </h6>
          <h6>
            {{
              store.plan === 'free' ? 'Help keep Fontio free with a ⭐⭐⭐⭐⭐️ review' : 'Say thanks with a ⭐⭐⭐⭐⭐️ review'
            }}
            -> <a href="https://apps.shopify.com/font-io">Here</a></h6>
        </div>
      </div>
      <!-- ends here -->
      <div class="row">
        <div class="col-md-12 mb-3">
          <div class="card" style="border:none;">
            <div class="card-body">
              <div class="row">
                <div class="col-md-8 mb-2">
                  <textarea placeholder="Custom Classes are here"
                            :disabled="store.plan === 'free' && store.shopify_plan !== 'affiliate'"
                            class="form-control customClassTextArea" rows="9" v-model="customClasses"></textarea>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">

                    <!-- for google fonts -->
                    <select v-if="store.plan === 'free' && store.shopify_plan !== 'affiliate'" disabled="true"
                            @change="onFontTypeSelect"
                            class="form-select form-select-lg mb-3">
                      <option selected value="">Select Font Type</option>
                      <option value="google_font">Google Font</option>
                      <option value="custom_font">Custom Font</option>
                    </select>
                    <select v-else @change="onFontTypeSelect"
                            class="form-select form-select-lg mb-3">
                      <option selected value="">Select Font Type</option>
                      <option value="google_font">Google Font</option>
                      <option value="custom_font">Custom Font</option>
                    </select>
                    <!-- ends here -->

                    <!-- for google fonts -->
                    <select v-if="isGoogleFont" class="form-select form-select-lg mb-3"
                            @change="fontSelect">
                      <option selected value="">Select Font</option>
                      <option v-for="font,index in fonts" :key="index" v-bind:value="index">{{ font.family }}</option>
                    </select>
                    <!-- ends here -->

                    <!-- for custom fonts -->
                    <select
                        v-if="!isGoogleFont && store.plan === 'paid' || !isGoogleFont && store.shopify_plan==='affiliate'"
                        class="form-select form-select-lg mb-3"
                        @change="fontSelect">
                      <option selected value="">Select Font</option>
                      <option v-for="font in fonts" :key="font.id" v-bind:value="font.id">{{ font.name }}</option>
                    </select>
                    <select v-if="store.plan === 'free' && store.shopify_plan !=='affiliate'" disabled="true"
                            class="form-select form-select-lg mb-3"
                            @change="fontSelect">
                      <option selected value="">Select Font</option>
                      <option v-for="font in fonts" :key="font.id" v-bind:value="font.id">{{ font.name }}</option>
                    </select>
                    <!-- ends here -->

                    <!-- only for google fonts -->
                    <div class="mb-3" v-if="isGoogleFont">
                      <select class="form-select form-select-lg mb-3" @change="variantSelect">
                        <option selected value="">Select Variant</option>
                        <option v-for="variant,index in variants" :key="index" value:="variant">{{ variant }}</option>
                      </select>
                    </div>
                    <!-- ends here -->

                    <!-- for font size -->
                    <div class="mb-3" v-if="isGoogleFont">
                      <select class="form-select form-select-lg mb-3" @change="fontSelectSize">
                        <option selected value="">Select Font Size</option>
                        <option v-for="size,index in font_sizes" :key="index" value:="size">{{ size }}</option>
                      </select>
                    </div>
                    <!-- ends here -->

                  </div>

                  <div class="row">
                    <!-- ends here -->
                    <div class="col-md-6">
                      <button v-if="store.plan === 'free' && store.shopify_plan !=='affiliate'" disabled="true"
                              class="btn  btn-default w-100 btn-grad"
                              @click="saveFont"><i class="fad fa-save"></i> Save
                      </button>
                      <button v-else class="btn  btn-default w-100 btn-grad" @click="saveFont"><i
                          class="fad fa-save"></i> Save
                      </button>

                      <clip-loader :loading="loader.haveClassesSaved" :color="color" class=" mt-3 mb-3"></clip-loader>
                    </div>

                    <!-- <div class="col-md-6">
                       <button v-if="store.plan === 'free'" disabled="true" class="btn  btn-default w-100 btn-grad"
                               @click="startPicker"><i
                           class="fad fa-crosshairs"></i> Picker
                       </button>
                       <button v-else class="btn  btn-default w-100 btn-grad"
                               @click="startPicker"><i
                           class="fad fa-crosshairs"></i> Picker
                       </button>
                     </div>-->

                  </div>

                </div>

              </div>
              <h5 class="mt-5 text-center">

              </h5>
            </div>
          </div>
        </div>
      </div>
      <!--
          <div class="row mt-3">
            <div class="col-md-12">

                <h4>Custom Elements</h4>
                <hr/>

            </div>
          </div> -->


    </div>


  </div>
</template>

<script>

import {vueTopprogress} from 'vue-top-progress'


import NotificationBar from '../utils/NotificationBar.vue'

//config here
const {API_URL} = require('../../../config.js');

import {saveCustomFontAPI, saveFontAPI} from '../../service/FontService.js'

import Vue from 'vue';


//loader vue -spinner
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import {getStoreInfoAPI} from "@/service/StoreService";

export default {
  name: 'Premium',
  data: function () {
    return {
      shop_url: '',
      element: new Set(),
      shop_token: '',
      variants: [],
      fonts: [],
      isGoogleFont: false,
      customClasses: '',
      color: 'red',
      font_sizes: ['default', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 18, 24, 36, 48, 60, 72, 84, 96, 108, 120],
      store: {
        plan: '',
        shopify_plan: ''
      },
      loader: {
        hasThemesLoaded: false,
        haveClassesSaved: false,
      },
      notification: {
        notification_text: '',
        notification_class: '',
        isNotification: false,
      },
      action: {
        selected_font: '',
        selected_tag: '',
        selected_type: '',
        selected_variant: '',
        selected_font_size: '',
        sample_text: ''

      },
    }
  },
  components: {
    vueTopprogress,
    NotificationBar,
    ClipLoader,
  },
  methods: {

    fontSelect: function (event) {
      //check if fonts are selected
      if (this.isGoogleFont) {
        this.action.selected_font = event.target.value;
        if (this.action.selected_font)
          this.variants = this.fonts[this.action.selected_font].variants;
      } else {
        //if anything is selected (from custom font)
        this.action.selected_font = this.fonts.filter((font) => font.id == event.target.value)[0]
        this.variants = []
      }

    },

    variantSelect: function (event) {

      this.action.selected_variant = event.target.value;
    },


    //utility to generateFont URL
    generateFontURL(font, variant) {
      let FONT_URL = 'https://fonts.googleapis.com/css2?family=' + font.split(' ').join('+');
      let isnum = /^\d+$/.test(variant);
      if (isnum) {
        FONT_URL += `:wght@${variant}&display=swap`;
      } else {
        //case if it is italic or regular
        if (variant === 'regular') {
          FONT_URL += `&display=swap`
        } else if (variant == 'italic') {
          FONT_URL += `:ital@1&display=swap`;
        } else {
          //all combined variants are here
          const weight = variant.replace(/[^0-9]/g, '');
          FONT_URL += `:ital,wght@1,${weight}&display=swap`;
        }
      }
      return FONT_URL;
    },
    fontSelectSize: function (event) {
      this.action.selected_font_size = event.target.value;
    },
    saveFont: function () {

      let font_type = '', font = '', src = '', size = '';

      if (!this.action.selected_type) {
        //if font has not been selected
        this.notify(true, 'alert alert-warning', '<b>Please Select Font Type</b>');
        return;
      }


      if (!this.action.selected_font) {
        //if font has not been selected
        this.notify(true, 'alert alert-warning', '<b>Please Select Font</b>');
        return;
      }
      if (!this.action.selected_font_size && this.isGoogleFont) {
        //if font has not been selected
        this.notify(true, 'alert alert-warning', '<b>Please Select Size</b>');
        return;
      }

      let custom_classes = this.customClasses.trim().split('\n');
      custom_classes = custom_classes.filter(item => item);


      if (Array.isArray(custom_classes) && !custom_classes.length) {
        //alert here when classes are empty
        this.notify(true, 'alert alert-warning', '<b>Please add elements using picker</b>');
        return;
      }

      if (this.isGoogleFont) {

        //check if everything is proper here
        if (!this.action.selected_variant) {
          //if font has not been selected
          this.notify(true, 'alert alert-warning', '<b>Please Select Variant</b>');
          return;
        }
        //ends here
        //if saved will be a google font
        font = this.fonts[this.action.selected_font].family;
        const variant = this.action.selected_variant;
        src = this.generateFontURL(font, variant);
        font_type = 'google_font';
        size = this.action.selected_font_size

      } else {
        const data = this.action.selected_font;
        font = data.f_hash
        src = data.src
        font_type = 'custom_font';
      }


      console.log('FONT_TYPE' + font_type);
      console.log('FONT NAME: ' + font);
      console.log('FONT SRC: ' + src);


      this.loader.haveClassesSaved = true;

      //save the elements to store (here's the APi Call)
      if (this.isGoogleFont) {
        saveFontAPI(this.shop_url, font_type, this.shop_token, [custom_classes, font, src, size])
            .then((data) => {

              console.log('Added all elements' + data);
              this.loader.haveClassesSaved = false;

              Vue.$toast.default('Elements Saved Successfully.', {
                // optional options Object
                duration: 1000,
              });

            });
        //ends here
      } else {
        saveCustomFontAPI(this.shop_url, font_type, this.shop_token, custom_classes, font, src, size)
            .then((data) => {

              console.log('Added all elements' + data);
              this.loader.haveClassesSaved = false;

              Vue.$toast.default('Elements Saved Successfully.', {
                // optional options Object
                duration: 1000,
              });

            });
        //ends here
      }


      //close the nitify here
      this.notify(false);


    },

    notify: function (flag, notification_class = '', notification_text = '') {
      this.notification.isNotification = flag;
      this.notification.notification_text = notification_text;
      this.notification.notification_class = notification_class;
    },

    onFontTypeSelect: function (event) {

      this.action.selected_type = event.target.value;
      this.action.selected_font = '';
      this.action.selected_variant = '';

      //reset the fonts array
      this.fonts = [];

      if (this.action.selected_type == 'google_font') {

        this.isGoogleFont = true;

        //if these fonts are google fonts
        let url = 'https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDnahbcJG1KvljUOxVcE9ERmP1q2oMc4Q8';
        fetch(url)
            .then(response => response.json())
            .then((data) => {

              this.fonts = data.items.reduce((a, x, index) => {
                a[index] = {'family': x.family, 'variants': x.variants};
                return a;
              }, {})
            });

      } else {

        if (this.action.selected_type == 'custom_font') {

          this.isGoogleFont = false;
          //if these fonts are custom fonts
          //load custom fonts here
          let url = `${API_URL}/api/font?domain=${this.shop_url}&type=custom_font&is_active=active`;
          fetch(url)
              .then(response => response.json())
              .then((data) => {
                this.fonts = data.fonts;
              });

        }


      }
    },

    startPicker: function () {

      const t = window.open(`https://${this.shop_url}`, "_blank", "location=0;width = 900px;height = 800px");
      window.addEventListener('message', this.eventListenerAPI.bind(this, t));
    },

    eventListenerAPI: function (child, event) {
      console.log('W have received', event.data);
      if (event.data.msg == 'picker' && event.data.mode === 'init') {
        child.postMessage({
          mode: "start",
          msg: "picker"
        }, '*');

        console.log('We have posted shit');
      } else {

        //if data has been received
        if (event.data.msg == 'picker' && event.data.mode === 'picked') {
          const element = event.data.data;
          this.element.add(element);
          //console.log('ELEMENT IS',element);
        }

        this.customClasses = Array.from(this.element).join('\n');

      }


    }

  },


  mounted() {


    this.shop_url = document.getElementById('shop_domain').value
    this.shop_token = document.getElementById('shop_token').value

    // this.shop_url = 'shristorey.myshopify.com'
    // this.shop_token = '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'
    getStoreInfoAPI(this.shop_url, this.shop_token)
        .then((data) => {
          const store_info = data;
          this.store.plan = store_info['store']['plan'];
          this.store.shopify_plan = store_info['store']['shopify_plan_name'];
        });
    // ends here

    this.$refs.topProgress.start();
    // Use setTimeout for demo
    setTimeout(() => {
      this.$refs.topProgress.done()
    }, 1000);

  },

  destroyed() {
    console.log('Feels like destryoed');
  }
}
</script>

<style>

.btn-grad {
  background-image: linear-gradient(to right, #4776E6 0%, #8E54E9 51%, #4776E6 100%)
}

.btn-grad {
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.customClassTextArea {
  resize: none;
}

</style>
