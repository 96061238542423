const { API_URL } = require('../../config.js');

function getStoreInfoAPI(shop_url, shop_token){

	let STOREINFO_API_URL =  `${API_URL}/api/store/info?domain=${shop_url}&access_token=${shop_token}`;
	return fetch(STOREINFO_API_URL)
	.then((data) => data.json())
}




function resetStoreAPI(domain , access_token ){
	const STORE_API_URL = `${API_URL}/api/store/reset`
	let data = {
		'domain':domain,
		'access_token':access_token,
	};
	return fetch(STORE_API_URL,{
		method:'POST',
		headers:{
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data) 
	})
	.then((data) => data.json())
}


module.exports = {
	getStoreInfoAPI,
	resetStoreAPI,
}