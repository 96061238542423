<template>
  <div>
  
  <vue-topprogress ref="topProgress" color="pink" height:=4></vue-topprogress>
  <div class="container mt-5">
    
    <div class="row">
      <div class="col-md-12">
        <div class="row">
            <div class="col-md-12 mt-3 text-center">
              <h3>Settings</h3>
            </div>
        </div>
        <br/>
        <!-- each font here -->
        <div class="row">
            <div class="col-md-3 mb-3">
              <!-- only one here -->
                <button class="btn btn-default w-100 btn-grad" @click="onStoreReset"><i class="fad fa-power-off"></i> RESET STORE</button>
                <a href='/logout'><button class="btn btn-default w-100 btn-grad"><i class="fas fa-sign-out-alt"></i>Logout</button></a>

            </div>
            <div class="col-md-1">
                <clip-loader  :loading="loader.hasCompleteReset" :color="color" class="mt-1"></clip-loader>
            </div>

        </div>

        <!-- ends here -->

      </div>
    </div>
  </div>
</div>
</template>

<script>


//loader vue -spinner
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

import { vueTopprogress } from 'vue-top-progress'

import { getStoreInfoAPI , resetStoreAPI } from '../../service/StoreService.js'


import Vue from 'vue';

export default {
  name: 'Settings',
  data:function(){
    return {
      shop_url:'',
      shop_token:'',
      color:'red',
      loader:{
        hasCompleteReset:false,
      }
    }
  },
  components: {
     vueTopprogress,
     ClipLoader,
  },
  methods:{

    onStoreReset:function(){

      this.loader.hasCompleteReset = true;
      //post the API call to clear the store
      resetStoreAPI(this.shop_url , this.shop_token)
      .then((data)=>{
        if(data.status){

          Vue.$toast.default('Store Reset Successfully.', {
                  // optional options Object
                  duration:1000,
          });

          this.loader.hasCompleteReset = false;
          //route to dashboard
          this.$router.push({ name: 'dashboard' });

        }
      });
    }

  },
  mounted(){

      
      this.shop_url = document.getElementById('shop_domain').value
      this.shop_token = document.getElementById('shop_token').value

      // this.shop_url = 'shristorey.myshopify.com'
      // this.shop_token = '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'

      //get current theme of the store

      getStoreInfoAPI(this.shop_url, this.shop_token)
      .then((data)=>{
        const store_info = data;
        console.log(store_info);
      });

      //ends here 
    
      this.$refs.topProgress.start();
      // Use setTimeout for demo
      setTimeout(() => {
        this.$refs.topProgress.done()
      }, 1000);

  }
}
</script>

<style>

.btn-grad {background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%)}
         .btn-grad {
            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            background-size: 200% auto;
            color: white;            
            box-shadow: 0 0 20px #eee;
            border-radius: 10px;
            display: block;
          }

  .btn-grad:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }


</style>
