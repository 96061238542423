<template>
  <div id="app" data-app>
    <vue-topprogress ref="topProgress" color="pink" :height=4></vue-topprogress>
    
    <Header :plan="store.plan" v-if="!isLogin"></Header>  

    <transition

      name="fade"
      mode="out-in"
    >

    <router-view></router-view>
    </transition>

  </div>
</template>


<script>

import Header from './components/utils/Header.vue'

import { vueTopprogress } from 'vue-top-progress'


import { getStoreInfoAPI } from './service/StoreService.js'

import Vuetify from 'vuetify/lib/framework';
  import Vue from 'vue';

Vue.use(Vuetify);
export default {
  name: 'App',
  data:function(){
    return {
      store:{
        plan:'',
      }
    }
  },
  components: {
    Header,
    vueTopprogress,
  },
  methods:{
    
  },
  mounted(){



      this.shop_url = document.getElementById('shop_domain').value
      this.shop_token = document.getElementById('shop_token').value


      
      // this.shop_url = 'shristorey.myshopify.com'
      // this.shop_token = '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'



      //get current theme of the store
      getStoreInfoAPI(this.shop_url, this.shop_token)
      .then((data)=>{
        const store_info = data;
        this.store.plan = store_info['store']['plan'];

      });


      //ends here 
    

      this.$refs.topProgress.start();
      // Use setTimeout for demo
      setTimeout(() => {
        this.$refs.topProgress.done()
      }, 1000);    

  },
  computed: {
    isLogin() {
      return this.$route.path === '/login'
    }
  }

}
</script>

<style>


#app {

  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    transition: opacity 0.2s ease;
}

.fade-leave {

}

.fade-leave-active {
    transition: opacity 0.2s ease;
    opacity: 0;
}

</style>
