<template>

  <div>
  <vue-topprogress ref="topProgress" color="pink" height=4></vue-topprogress>
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-12 text-center">
             
          <main class="form-signin">
          
              <img class="mb-4" src="https://cdn.shopify.com/app-store/listing_images/c11eb65a82601456f3ead7a3a6e227d4/icon/CLqkoKygtfECEAE=.png?height=84&width=84" alt="" width="72" height="57" style="border-radius:50%;">
              <h1 class="h3 mb-3 fw-normal">Please sign in</h1>

              <form action="/shopify">
              <div class="form-floating mb-3 mt-1">
                <input type="text" name="shop" class="form-control" v-model="store_url" placeholder="mystore.myshopify.com">
                <label>mystore.myshopify.com</label>
              </div>
              <button type="submit" class="w-100 btn btn-lg btn-default btn-grad">Sign in</button>
              <p class="mt-5 mb-3 text-muted">&copy; 2021–2022</p>
              </form>

          </main>

      </div>
    
    </div>
  </div>
</div>
</template>

<script>

import { vueTopprogress } from 'vue-top-progress'

export default {
  name: 'Login',
  data:function(){
    return {
      store_url:'',
    }
  },
  components: {
     vueTopprogress,
  },
  methods:{
  },
  mounted(){
    

      this.$refs.topProgress.start();
      // Use setTimeout for demo
      setTimeout(() => {
        this.$refs.topProgress.done()
      }, 1000);

  }

  

}
</script>

<style scoped>


.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}


.btn-grad {background-image: linear-gradient(to right, #4776E6 0%, #8E54E9  51%, #4776E6  100%)}
         .btn-grad {
            text-align: center;
            transition: 0.5s;
            background-size: 200% auto;
            color: white;            
            box-shadow: 0 0 20px #eee;
            border-radius: 10px;
            display: block;
          }

          .btn-grad:hover {
            background-position: right center; /* change the direction of the change here */
            color: #fff;
            text-decoration: none;
          }


</style>
